








import router from '@/router';
import { Vue } from 'vue-property-decorator';
import Component from '@/routeComponent'
import store from '@/store';
import IconTitleTextCard from '@/components/iconTitleTextCard.vue';
import * as VM from '@/viewModel';
import * as OM from '@/model';
import { AssetClient } from '@/services/Services';

@Component({
    components: {
        IconTitleTextCard
    }
})
export default class MicroClassesSwitch extends Vue {

    microCategories: string[] = [];
    filterVm: OM.AssetFilterVm = new OM.AssetFilterVm();
    
    beforeRouteEnter(to, from, next){
        let filterVm = new OM.AssetFilterVm();
        filterVm.assetType = to.params.assetType;
        filterVm.macroClass = to.params.macroClass;
        AssetClient.getAssetClasses(filterVm)
        .then(data => {
            next((vc: MicroClassesSwitch) => {
                vc.microCategories = data;
                vc.filterVm = filterVm;
            });
        })
    }

    select(cat: string){
        this.$router.push(this.$route.params.macroClass + '/' + cat)
    }
}

